<template>
  <div class="main">
        <div class="topImg survey-top-img">
          <!-- <img
            src="../assets/images/top.png"
            alt="top"
            class="img-fluid topDesign"
          /> -->
        </div>
        <div class="container">
            <div class="content">
                <div class="hello-word survey-content survey-last">
                    <h4 class="mt-2">Hello, <b>{{ loggedInUser ? loggedInUser.user_firstname : ''}}</b>!</h4>
                    <h2>Thank You</h2>
                    <h6>Your information is valuable to the organization.</h6>
                    <div class="survey-content__img">
                      <!-- <img src="../assets/images/suvery-thank.svg" alt="img-fluid"> -->
                    </div>
                </div>
                <!-- <div class="survey-submit">
                    <button class="btn btn-submit">SUMBIT SURVEY</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from "vuex";
  import { serverFileStoragePath } from "@/constants/consts";

  export default {
    name: "Thank-you",
    components: {
      //HelloWorld
    },
    data() {
      return {
        assignedTemplateId: "",
        showrecord: false,
        quesionDetail: {},
        questionIndex: 0,
        countDown: 10,
        serverFileStoragePath: serverFileStoragePath
      };
    },
    computed: {
      ...mapState(["authData", "questionsVideo"]),
      ...mapGetters([
        "loggedInUser",
        "questionVideoArr",
        "currentQuestion",
        "loading",
        'allFeedbackFormTemplates',
        "error"
      ])
    },
    async mounted() {
      const assignedTemplate = this.allFeedbackFormTemplates[0];
      if(assignedTemplate.is_completed == false) {
         this.assignedTemplateId = assignedTemplate.id;
          const formData = {
            assign_id: this.assignedTemplateId,
            completed: true,
            completed_task_count: this.allFeedbackFormTemplates.length,
            total_completed_score: 0,
            user_firstname:   this.loggedInUser.user_firstname,
            user_lastname: this.loggedInUser.user_lastname
          }
          await this.setInterviewCompleted(formData)
          this.$store.commit('resetQuestionData')
          localStorage.removeItem("vuex");
      }
    },
    watch: {
      // async countDown(value) {
      //   if(value == 0) {
      //     this.$store.commit('resetQuestionData')
      //     await this.signOut()
      //     localStorage.removeItem("vuex");
      //     this.$router.push("/");
      //   }
      // }
    },
    methods: {
      ...mapActions(["signOut",'setInterviewCompleted', 'getInterviewsSummery']),
       countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      },
    }
  };
</script>